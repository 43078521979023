import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solicitud-prueba',
  templateUrl: './solicitud-prueba.component.html',
  styleUrls: ['./solicitud-prueba.component.css']
})
export class SolicitudPruebaComponent implements OnInit {
  concesionario: any;
  @ViewChild('modosconduccion') modosConduccionModal: ElementRef;
  public pais: string = "";
  public spain: boolean = false;
  public italy: boolean = false;
  public france: boolean = false;
  public mostrarTestDrive: boolean = false;
  public lang: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.checkLanguageAndInitialize(); // Esperar a que el idioma esté listo
  }

  private loadExternalScripts(): void {
    // Cargar el script de concesionario-prueba.js
    let node = document.createElement('script');
    node.src = "/assets/js/concesionario-prueba.js";
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private loadGoogleMaps(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAYK1Rx8uRxHfYrJXXNvca3XVRboVSef_4&libraries=places,geometry&solution_channel=GMP_QB_locatorplus_v4_cABCDE";
      script.async = true;
      script.defer = true; // Esto garantiza que el script se ejecute después de que el DOM esté completamente cargado
      script.onload = () => resolve();  // Cuando el script cargue exitosamente, resolvemos la promesa
      script.onerror = (error) => reject('Error al cargar Google Maps');  // Si hay un error, lo rechazamos
      document.head.appendChild(script);  // Añadimos el script al head del documento
    });
  }
  
  
  private initializeMap(latitude: number, longitude: number): void {
    // Llamamos a la función para cargar el script de Google Maps y esperamos a que termine de cargar
    this.loadGoogleMaps().then(() => {
      // Este bloque solo se ejecutará cuando el script de Google Maps se haya cargado completamente
      const mapOptions = {
        center: new google.maps.LatLng(latitude, longitude),
        zoom: 15
      };
      const map = new google.maps.Map(document.getElementById('map'), mapOptions);
  
      // Marcador en la ubicación
      new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
        title: 'Mi Ubicación'
      });
  
      console.log('Mapa inicializado exitosamente');
    }).catch((error) => {
      // Si hubo un error al cargar el script de Google Maps, lo mostramos en consola
      console.error('Error al cargar el mapa:', error);
    });
  }
  

  private checkLanguageAndInitialize(): void {
    const storedLang = sessionStorage.getItem("lang");
    if (storedLang) {
      this.initializePopup(storedLang);
      this.loadExternalScripts();  // Cargar scripts después de determinar el idioma
      this.loadGoogleMaps();  // Cargar Google Maps
    } else {
      const interval = setInterval(() => {
        const lang = sessionStorage.getItem("lang");
        if (lang) {
          clearInterval(interval);
          this.initializePopup(lang);
          this.loadExternalScripts();  // Cargar scripts después de determinar el idioma
          this.loadGoogleMaps();  // Cargar Google Maps
        }
      }, 100);
    }
  }

  private initializePopup(lang: string): void {
    this.lang = lang;

    // Configura las variables según el idioma
    if (lang === 'it') {
      this.pais = "Italy";
      this.italy = true;
    } else if (lang === 'fr') {
      this.pais = "France";
      this.france = true;
    } else if (lang === 'es') {
      this.pais = "Spain";
      this.spain = true;
    } else {
      this.router.navigate(['/']);
      return;
    }

    this.mostrarTestDrive = ['es', 'fr', 'it'].includes(lang);
    console.log("Idioma configurado:", lang);
  }

  cargarConcesionario(): void {
    const variableRecuperada = localStorage.getItem('concesionario');
    if (variableRecuperada !== null) {
      console.log('Valor recuperado del localStorage:', variableRecuperada);
      this.concesionario = variableRecuperada;
    } else {
      console.log('La variable no existe en el localStorage.');
    }

    window.scrollTo({
      top: 700,
      left: 100,
      behavior: "smooth",
    });
  }
}
