import { Component, HostListener, OnInit } from '@angular/core';
import { ConcesionariosService } from 'src/app/services/concesionarios.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { GeoapiService } from 'src/app/services/geoapi.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prueba-conduccion-popup',
  templateUrl: './prueba-conduccion-popup.component.html',
  styleUrls: ['./prueba-conduccion-popup.component.css']
})
export class PruebaConduccionPopupComponent implements OnInit {
  language: string;
  testimonials: any;
  url = 'https://nerva.eco/solicitar-prueba';
  testimonial: any;
  popup: boolean = false;
  popuphome: boolean = true;
  public lang: string;
  idioma: string;
  windowWidth: number;
  italian: boolean = false;
  ver: boolean = false;
  public mostrarTestDrive: boolean = false;
  isLoading: boolean = true; // Variable para controlar el loading

  constructor(
    public concesionariosService: ConcesionariosService,
    private router: Router,
    private location: Location,
    private geoApiService: GeoapiService,
    private translate: TranslateService
  ) {
    this.windowWidth = window.innerWidth;
    this.ver = true;
  }

  ngOnInit(): void {
    // Mostrar loading mientras espera el idioma
    this.checkLanguageAndInitialize();
  }

  private checkLanguageAndInitialize(): void {
    const storedLang = sessionStorage.getItem("lang");
    if (storedLang) {
      this.initializePopup(storedLang);
    } else {
      const interval = setInterval(() => {
        const lang = sessionStorage.getItem("lang");
        if (lang) {
          clearInterval(interval);
          this.initializePopup(lang);
        }
      }, 100);
    }
  }

  private initializePopup(lang: string): void {
    this.lang = lang;

    // Configura mostrarTestDrive basado en el idioma
    if (lang === 'es' || lang === 'fr' || lang === 'it') {
      this.mostrarTestDrive = true;
    } else {
      this.mostrarTestDrive = false;
    }

    // Lógica relacionada con la URL
    if (this.location.path() === '') {
      this.popuphome = true;
    } else if (['?lang=fr', '?lang=it', '?lang=en', '?lang=es'].includes(this.location.path())) {
      this.popuphome = true;
    } else if (this.location.path() === '/modeloExe') {
      this.popuphome = true;
    }

    // Obtener testimonios destacados
    this.concesionariosService.getHighlightsTestimonials().subscribe({
      next: (r: any) => {
        this.testimonials = r.message;
        console.log(this.testimonials);
      },
      error: (err: any) => {
        console.error('Error al obtener testimonios:', err);
      }
    });

    this.popup = true; // Mostrar el popup
    this.isLoading = false; // Finalizar loading
  }

  asignarLogo() {
    this.testimonial.logo = '/files/android-chrome-256x256.png';
  }

  cerrarPopup() {
    this.popuphome = false;
    const backdrop = document.querySelector('.modal-backdrop.fade.show');
    if (backdrop) {
      backdrop.remove();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  activateLink() {
    const enlace = document.getElementById('elBoton');
    if (enlace) {
      enlace.click();
    }
  }
}
