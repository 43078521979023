import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeoapiService } from 'src/app/services/geoapi.service';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {
  windowWidth: number;
  public lang: string;
  idioma: string;
  public mostrarTestDrive: boolean = false;
  language: string;


 constructor(geoApiService: GeoapiService, private route: ActivatedRoute) {
  //   geoApiService.getIPAddress().then(
  //     (ipAddress => {
  //         geoApiService.getCountry(ipAddress).subscribe({
  //             next: (r: any) => {
  //                 this.lang = r.message?.country?.iso_code.toLowerCase();
  //                 console.log(this.lang)

  //             }
  //         })
  //     })
  //   )
}

  ngOnInit(): void {
    this.checkLanguageAndInitialize();

    if(sessionStorage.getItem("lang") == "es" || sessionStorage.getItem("lang") == "fr" || sessionStorage.getItem("lang") == "it"){
      this.mostrarTestDrive = true;
    }else {
      this.mostrarTestDrive = false;
    }

    let leng = 'en';
    this.language = leng;
    
    setTimeout(() => {
      this.route.queryParams.subscribe((params)=> {
        
        if(params['lang']){
          sessionStorage.setItem("lang", params['lang']);
          this.idioma = params['lang'];

        } else if (sessionStorage.getItem("lang")) {
          let lang = sessionStorage.getItem("lang")
          let language = lang;
          this.idioma = lang;

        } else {
          let lang = 'en';
          let language = lang;
          this.idioma = lang;
        }

      });
    }, 1)



  }
  private checkLanguageAndInitialize(): void {
    const storedLang = sessionStorage.getItem("lang");
    if (storedLang) {
      this.initializePopup(storedLang);
    } else {
      const interval = setInterval(() => {
        const lang = sessionStorage.getItem("lang");
        if (lang) {
          clearInterval(interval);
          this.initializePopup(lang);
        }
      }, 100);
    }
  }
  private initializePopup(lang: string): void {
    this.lang = lang;

    // Configura mostrarTestDrive basado en el idioma
    if (lang === 'es' || lang === 'fr' || lang === 'it') {
      this.mostrarTestDrive = true;
    } else {
      this.mostrarTestDrive = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth; 
  }

}
