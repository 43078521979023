import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-drive-button',
  templateUrl: './test-drive-button.component.html',
  styleUrls: ['./test-drive-button.component.css']
})
export class TestDriveButtonComponent implements OnInit {
  public mostrarTestDrive: boolean = false;
  isLoading: boolean = true; // Variable para controlar el loading
  public lang: string;

  constructor() { }

  ngOnInit(): void {
    this.checkLanguageAndInitialize();
  }

  private checkLanguageAndInitialize(): void {
    const storedLang = sessionStorage.getItem("lang");
    if (storedLang) {
      this.initializePopup(storedLang);
    } else {
      const interval = setInterval(() => {
        const lang = sessionStorage.getItem("lang");
        if (lang) {
          clearInterval(interval);
          this.initializePopup(lang);
        }
      }, 100);
    }
  }

  private initializePopup(lang: string): void {
    this.lang = lang;

    // Configura mostrarTestDrive basado en el idioma
    if (lang === 'es' || lang === 'fr' || lang === 'it') {
      this.mostrarTestDrive = true;
    } else {
      this.mostrarTestDrive = false;
    }


    this.isLoading = false; // Finalizar loading
  }

}
