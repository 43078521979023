<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Tu Página</title>
  <!-- Agrega las dependencias de Bootstrap y jQuery -->
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
  <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
</head>
<body>



      <div *ngIf="windowWidth > 767">
        <div *ngIf="popuphome" class="modal " id="pruebaconduccionpopuphome" style="height:auto">
          <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center full-screen-overlay">
            <div class="spinner"></div>
          </div>

         <div *ngIf="lang == 'es' || lang == 'fr' || lang == 'it' && ver && !isLoading" class="modal-dialog modal-dialog-centered pt-2">
          <div class="modal-content">
            <div class="">
              <div class="background-image2 ">
                <div class="">
                  <div id="carouselExampleIndicators" class="carousel slide " style="width:100%" data-ride="carousel" data-interval="5000">
                    <div class="carousel-inner">
                      <!-- Primer conjunto de contenido -->
                      <div class="carousel-item ">
                        <div class="ipad" style="background-color:black; height:auto">
                          <div class="row text-start ">
                            <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:1em;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                              </a>
                            </div>
                            <div class="col-6 mt-3 d-flex poptext justify-content-center">
                              <a href="/pruebadeconduccion" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:1em;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                              </a>
                            </div>
                            <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                              </a>
                            </div> -->
                          </div>
                          <div class="row text-start ml-2" >
                            <div class="col-6 mt-3 d-flex justify-content-start" >
                              <div class="col-12 text-left " >
                                <span style="color:white; font-size:1em;font-weight:10">{{'La prensa habla sobre NERVA EXE'|translate}}</span>
                                <h2 class="mt-3" style="font-weight:500; padding-right:2em;font-size:1.5em">{{'El maxiscooter eléctrico con baterías BYD'|translate}}</h2>
                                <div class="botonpop row ml-1 mt-5" style="width:90%;background-color:#ffffff; border-radius:20px">
                                  <div class="col-12 mt-1 mb-1  " style=" border-radius:20px">
                                    <div class="d-flex justify-content-center mt-2 mb-2" >
                                      <a href="/solicitar-prueba"style="text-decoration: none">
                                        <span style="color:black; font-weight:500;font-size:0.8em" class="text-center">
                                          {{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                        </span> 
                                        <mat-icon class="" style="color:white; font-size: 14px;height:22px; background-color:black; border-radius:50%; padding-top:3px">
                                          arrow_forward
                                        </mat-icon>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-3 d-flex justify-content-center ocultar" style="padding-right:2em; height:20em">
                            <div *ngIf="language == 'en' || language == 'es'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                            <div *ngIf="language == 'fr'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/yeTPFqxAtSY" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                            <div *ngIf="language == 'it'"  class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/0z0SUEOepwY" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                            <div *ngIf="language !== 'it' && language !== 'fr' && language !== 'en' && language !== 'es'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                            </div>
                          </div>
                            <div class="row mt-5">
                              <div class="col-12 d-flex justify-content-left ocultar"style="padding-right: 0px;padding-left: 3em;">
                                <p style="color:white; font-size:16px;" >{{'Síguenos en nuestras redes sociales para más noticias:'|translate}}      
                                  <a href="https://www.instagram.com/nervamobility/" target="_blank">
                                    <img class="ml-1" style="height: 2em;" src="/assets/images/icons8-instagram (1).svg" alt="">
                                  </a>
                                     <a href="https://www.youtube.com/@nerva.mobility" target="_blank">
                                    <img style="height: 2.2em; padding-left:10px" src="/assets/images/icons8-youtube.svg" alt="">
                                  </a>  
                                  <a href="https://www.tiktok.com/@nervamobility?is_from_webapp=1&sender_device=pc" target="_blank">
                                    <img style="height: 2em; padding-left:10px" src="/assets/images/icons8-tik-tok-48.png" alt="">
                                  </a>
                                  <a href="https://www.facebook.com/Nervamobility" target="_blank">
                                      <img style="height: 2em; padding-left:10px" src="/assets/images/icons8-facebook (1).svg" alt="">
                                  </a>
                                  <a href="https://es.linkedin.com/company/nerva.eco" target="_blank">
                                    <img style="height: 1.8em; padding-left:10px" src="/assets/images/linkedin-svgrepo-com.svg" alt="">
                                  </a>
                                </p>
                              </div>
                             
                            </div>
                          </div>
                          <div class="col-12 cols d-flex justify-content-center mt-2 mb-1">
                            <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                              <mat-icon color="white">close</mat-icon>
                              {{ 'Cerrar' | translate }}
                            </button>
                          </div>
                        </div>
                     
                      </div>
        
                      <!-- Segundo conjunto de contenido -->
                       <div class="carousel-item active">
                        <div class="ipad" style="background-color:black; height:auto">
                          <div class="row text-start ">
                            <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:1em;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                              </a>
                            </div>
                            <div class="col-6 mt-3 d-flex poptext justify-content-center">
                              <a href="/pruebadeconduccion" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:1em;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                              </a>
                            </div>
                            <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                              </a>
                            </div> -->
                          </div>
                          <div class="row text-start ml-2" >
                            <div class="col-6 mt-3 d-flex justify-content-start" >
                              <div class="col-12 text-left" >
                                <span style="color:white; font-size:1em;font-weight:10">{{'NERVA EXE renovado y más barato!!'|translate}}</span>
                                <h2 class="mt-3" style="font-weight:200;font-size:1.2em; padding-right:2em">{{'NUEVO PRECIO'|translate}} <br> {{'Desde'|translate}} <span style="font-size:1.2em;color:#007bff">{{'2.780 €'|translate}}</span> </h2>
                                <div class="mb-3">
                                  <span *ngIf="language == 'it'" class="pb-3" style="color:white; font-size:1em;font-weight:10">{{'compreso contributo Ecobonus con rottamazione, escluso IPT e messa in strada'|translate}}</span>

                                </div>

                                <div class="col-12 d-flex justify-content-left " style="padding:0;">
                                  <p class="col-12" style="color:white; font-size:1em; padding:0;" >{{"PVP: 5990 €. Puedes comprar la parte ciclo por 3.880 €, solicitar el MOVES III (1.100 € si no achatarras y 1.300 € si achatarras) alquilar las baterías por 39,90 € al mes durante 5 años."|translate}}
                                    <span *ngIf="language !== 'fr'" class="col-12" style="color:#007bff; font-size:1em; padding:0;" >{{"39,90 € al mes durante 5 años."|translate}}       
                                    </span>
                                    <span class="col-12" style="color:#ffffff; font-size:1em; padding:0;" >{{"PVP moto completa: 5.990 €"|translate}}       
                                    </span>       
                                  </p>
                                 
                    
                                </div>
                                <p *ngIf="language !== 'it' && language !== 'fr'" style="font-size: x-small;"><a href="/quiero-una-nerva">{{'Toda la info aquí.'|translate}}</a></p>

                                <div class="botonpop row ml-1" style="width:90%;background-color:#007bff; border-radius:20px">
                                  <div class="col-12 mt-1 mb-1  " style=" border-radius:20px">
                                    <div class="d-flex justify-content-center mt-2 mb-2" >
                                      <a href="/solicitar-prueba"style="text-decoration: none">
                                        <span style="color:black; font-weight:500;font-size:0.8em" class="text-center">
                                          {{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                        </span> 
                                        <mat-icon class="" style="color:white; font-size: 14px;height:22px; background-color:black; border-radius:50%; padding-top:3px">
                                          arrow_forward
                                        </mat-icon>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-3 d-flex justify-content-center ocultar" style="padding-right:2em; height:20em">
                             <img src="/assets/images/05.webp" alt="">
                            </div>
                       

                    
                            <div class="row mt-5">
                              <div class="col-12 d-flex justify-content-left ocultar"style="padding-right: 0px;padding-left: 3em;">
                                <p style="color:white; font-size:1em;" >{{'Síguenos en nuestras redes sociales para más noticias:'|translate}}      
                                  <a href="https://www.instagram.com/nervamobility/" target="_blank">
                                    <img class="ml-1" style="height: 2em;" src="/assets/images/icons8-instagram (1).svg" alt="">
                                  </a>
                                     <a href="https://www.youtube.com/@nerva.mobility" target="_blank">
                                    <img style="height: 2.3em; padding-left:10px" src="/assets/images/icons8-youtube.svg" alt="">
                                  </a>  
                                  <a href="https://www.tiktok.com/@nervamobility?is_from_webapp=1&sender_device=pc" target="_blank">
                                    <img style="height: 2.3em; padding-left:10px" src="/assets/images/icons8-tik-tok-48.png" alt="">
                                  </a>
                                  <a href="https://www.facebook.com/Nervamobility" target="_blank">
                                      <img style="height: 2.3em; padding-left:10px" src="/assets/images/icons8-facebook (1).svg" alt="">
                                  </a>
                                  <a href="https://es.linkedin.com/company/nerva.eco" target="_blank">
                                    <img style="height: 2.3em; padding-left:10px" src="/assets/images/linkedin-svgrepo-com.svg" alt="">
                                  </a>
                                </p>
                              </div>
                           
                            </div>
                            <div class="col-12 cols d-flex justify-content-center mt-2 mb-2">
                              <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                <mat-icon color="white">close</mat-icon>
                                {{ 'Cerrar' | translate }}
                              </button>
                            </div>

          
                          </div>
                        </div>
                      </div>
                    </div>
        

                    <a class="carousel-control-prev " href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div *ngIf="lang !== 'es' && lang !== 'fr' && lang !== 'it' && ver && !isLoading" class="modal-dialog modal-dialog-centered mt-5">
          <div class="modal-content">
            <div class="">
              <div class="background-image2">
                <div class="">
                  <div id="carouselExampleIndicators" class="carousel slide" style="width:100%" data-ride="carousel" data-interval="5000">
                    <div class="carousel-inner">

                      <div class="carousel-item active ">
                        <div class="" style="background-color:black; padding:1em; height:auto">
                          <div class="row text-start ">
                            <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:1em;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                              </a>
                            </div>
                            <div class="col-6 mt-3 d-flex poptext justify-content-center">
                              <a href="/pruebadeconduccion" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:1em;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                              </a>
                            </div>
                            <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                              </a>
                            </div> -->
                          </div>
                          <div class="row text-start ml-2" style="height: auto;">
                            <div class="col-6 mt-2 d-flex justify-content-start" >
                              <div class="col-12 text-left" style="display: flex;
                              flex-direction: column;
                              justify-content: center;" >
                                <span style="color:white; font-size:1em;font-weight:10">{{'La prensa habla sobre NERVA EXE'|translate}}</span>
                                <h2 class="mt-3" style="font-size:1.3em;font-weight:500; padding-right:2em">{{'El maxiscooter eléctrico con baterías BYD'|translate}}</h2>
                                <div *ngIf="mostrarTestDrive" class="botonpop row mt-5 ml-1" style="width:auto">
                                  <div class="col-12 mt-2 mb-2">
                                    <div class="d-flex justify-content-center">
                                      <a  href="/solicitar-prueba" style="text-decoration: none; display: inline-flex; align-items: center;">
                                        <span style="color:black; font-weight:500; font-size: 1em; line-height: 24px;">
                                          {{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                        </span>
                                        <mat-icon class="ml-1" style="color:white; font-size: 18px;height:22px; background-color:black; border-radius:50%; padding-top:1px">
                                          arrow_forward
                                        </mat-icon>
                                      </a>
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-3 d-flex justify-content-center ocultar" style="padding-right:2em; height:20em">
                            <div *ngIf="language == 'en' || language == 'es'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                            <div *ngIf="language == 'fr'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/yeTPFqxAtSY" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                            <div *ngIf="language == 'it'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/0z0SUEOepwY" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                            <div *ngIf="language !== 'it' && language !== 'fr' && language !== 'en' && language !== 'es'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                            </div>
                          </div>
                            <div class="row mt-5" style="width: 100%;">
                              <div class="col-12 d-flex justify-content-left ocultar"style="padding-right: 0px;padding-left: 3em;">
                                <p style="color:white; font-size:16px;" >{{'Síguenos en nuestras redes sociales para más noticias:'|translate}}      
                                  <a href="https://www.instagram.com/nervamobility/" target="_blank">
                                    <img class="ml-1" style="height: 2em;" src="/assets/images/icons8-instagram (1).svg" alt="">
                                  </a>
                                     <a href="https://www.youtube.com/@nerva.mobility" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-youtube.svg" alt="">
                                  </a>  
                                  <a href="https://www.tiktok.com/@nervamobility?is_from_webapp=1&sender_device=pc" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-tik-tok-48.png" alt="">
                                  </a>
                                  <a href="https://www.facebook.com/Nervamobility" target="_blank">
                                      <img style="height: 2em; padding-left:10px" src="/assets/images/icons8-facebook (1).svg" alt="">
                                  </a>
                                  <a href="https://es.linkedin.com/company/nerva.eco" target="_blank">
                                    <img style="height: 1.8em; padding-left:10px" src="/assets/images/linkedin-svgrepo-com.svg" alt="">
                                  </a>
                                </p>
                              </div>
                              <div class="col-12 cols d-flex justify-content-center mt-5">
                                <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                  <mat-icon color="white">close</mat-icon>
                                  {{ 'Cerrar' | translate }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
        

                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
  </div>
  </div>
  <div *ngIf="windowWidth <= 767">
      <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center full-screen-overlay">
        <div class="spinner"></div>
      </div>

    <div *ngIf="popuphome" class="modal " id="pruebaconduccionpopuphome" style="margin-top: 2em; width :90%;left:1em">

       <div *ngIf="lang =='es' || lang == 'fr' || lang == 'it'"class="modal-dialog modal-dialog-centered " style="width: 90%; "> 
        
            <div class="modal-content">
              <div class="">
                <div class="background-image2">
                  <div class="">
                    <div id="carouselExampleIndicators" class="carousel slide" style="width:100%" data-ride="carousel" data-interval="5000">
                      <div class="carousel-inner">
                        <!-- Primer conjunto de contenido -->
                        <div class="carousel-item " style="height: auto;">
                          <div class="" style="background-color:black; height:80vh; width:75%;padding-top: 0em; margin-left:5em">
                            <div class="row text-start  pl-1 pr-1 ">
                              <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                                </a>
                              </div>
                              <div class="col-6 mt-3 d-flex poptext justify-content-center">
                                <a href="/pruebadeconduccion" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                                </a>
                              </div>
                              <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                                </a>
                              </div> -->
                            </div>

                              <div class="col-12 mt-5 d-flex justify-content-left" style="padding: 0;">
                                <div class="col-12 text-left" >
                                  <span style="color:white; font-size:18px;font-weight:10">{{'La prensa habla sobre NERVA EXE'|translate}}</span>
                                  <h2 class="mt-3" style="font-weight:100;">{{'El maxiscooter eléctrico con baterías BYD'|translate}}</h2>
                                  <div class="botonpop row mt-3 ml-1">
                                    <div class="col-12">
                                      <div class="d-flex justify-content-center mt-2 mb-2" >
                                        <a href="/solicitar-prueba"style="text-decoration: none;">
                                          <span style="color:black; font-weight:500;" class="text-center">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                          </span> 
                                          <mat-icon class="" style="color:white; font-size: 24px; background-color:black; border-radius:50%;">
                                            arrow_forward
                                          </mat-icon>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 mt-3 d-flex justify-content-center text-center" style="margin-left:0.2em;">
                                <div *ngIf="language == 'en' || language == 'es'" class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item container-video" style="padding-left: 1em;" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language == 'fr'" class="embed-responsive embed-responsive-16by9">
                                    <iframe style="padding-left: 1em;"class="embed-responsive-item container-video" src="https://www.youtube.com/embed/yeTPFqxAtSY" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language == 'it'" class="embed-responsive embed-responsive-16by9">
                                    <iframe style="padding-left: 1em;"class="embed-responsive-item container-video" src="https://www.youtube.com/embed/0z0SUEOepwY" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language !== 'it' && language !== 'fr' && language !== 'en' && language !== 'es'" class="embed-responsive embed-responsive-16by9">
                                  <iframe class="embed-responsive-item container-video" style="padding-left: 1em;" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                            </div> 
                            
                                <div class="col-12 cols ml-4 d-flex justify-content-center mt-3">
                                  <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                    <mat-icon color="white">close</mat-icon>
                                    {{ 'Cerrar' | translate }}
                                  </button>
                                </div>

                            </div>
                          </div>
                           <!-- Segundo conjunto de contenido -->
                       <div class="carousel-item active">
                        <div class="" style="background-color:black; height:80vh; width:75%;padding-top: 0em; margin-left:5em">
                          <div class="row text-start  pl-1 pr-1 ">
                              <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                                </a>
                              </div>
                              <div class="col-6 mt-3 d-flex poptext justify-content-center">
                                <a href="/pruebadeconduccion" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                                </a>
                              </div>
                              <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                                </a>
                              </div> -->
                            </div>


                                <div class="col-12 mt-3 d-flex justify-content-start" style="padding: 0;" >
                                  <div class="col-12 text-left" >
                                    <span style="color:white; font-size:16px;font-weight:10">{{'NERVA EXE renovado y más barato!!'|translate}}</span>
                                    <h2 class="mt-3" style="font-weight:200; padding-right:2em">{{'NUEVO PRECIO'|translate}} <br> {{'Desde'|translate}} <span style="color:#007bff">{{'2.780 €'|translate}}</span> </h2>
                                    <div class="col-12 d-flex justify-content-left " style="padding:0;">
                                    </div>
                                    <p style="font-size: x-small;"><a href="/quiero-una-nerva">{{'Toda la info aquí.'|translate}}</a></p>
    
                                    <div class="botonpop row mt-3 ml-1"style="background-color:#007bff; border-radius:20px">
                                      <div class="col-12 mt-2 mb-2" style="background-color:#007bff; border-radius:20px">
                                        <div class="d-flex justify-content-center">
                                          <a href="/solicitar-prueba"style="text-decoration: none;">
                                            <span style="color:black; font-weight:500;" class="text-center">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                            </span> 
                                            <mat-icon class="" style="color:white; font-size: 24px; background-color:black; border-radius:50%;">
                                              arrow_forward
                                            </mat-icon>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                 <div class="col-12 mt-3 ml-1 d-flex justify-content-center ">
                                  <img height="200px" margin-left="2em" width="100%" src="/assets/images/05.webp" alt="">
                                </div> 
                                    <div class="col-12 cols ml-4 d-flex justify-content-center mt-3">
                                      <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                        <mat-icon color="white">close</mat-icon>
                                        {{ 'Cerrar' | translate }}
                                      </button>
                                    </div>
                              </div>
                   

                            </div> 
                          </div>
                        </div>
          
                       
                      </div>
          
  
                       <a class="carousel-control-prev " href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a class="carousel-control-next " href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
          <div *ngIf="lang !=='es' && lang !== 'fr' && lang !== 'it'" class="modal-dialog modal-dialog-centered " style="width: 90%; ">
            <div class="modal-content">
              <div class="">
                <div class="background-image2">
                  <div class="">
                    <div id="carouselExampleIndicators" class="carousel slide" style="width:100%" data-ride="carousel" data-interval="5000">
                      <div class="carousel-inner">

                        <div class="carousel-item active">
                          <div class="" style="background-color:black; height:80vh; width:75%;padding-top: 0em; margin-left:5em">
                            <div class="row text-start  pl-1 pr-1 ">
                              <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                                </a>
                              </div>
                              <div class="col-6 mt-3 d-flex poptext justify-content-center">
                                <a href="/pruebadeconduccion" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                                </a>
                              </div>
                              <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                                </a>
                              </div> -->
                            </div>

                              <div class="col-12 mt-5 d-flex justify-content-left" >
                                <div class="col-12 text-left" >
                                  <span style="color:white; font-size:18px;font-weight:10">{{'La prensa habla sobre NERVA EXE'|translate}}</span>
                                  <h2 class="mt-3" style="font-weight:100;">{{'El maxiscooter eléctrico con baterías BYD'|translate}}</h2>
                                  <div class="botonpop row mt-3 ml-1">
                                    <div class="col-12">
                                      <div class="d-flex justify-content-center mt-2 mb-2" >
                                        <a href="/solicitar-prueba"style="text-decoration: none;">
                                          <span style="color:black; font-weight:500;" class="text-center">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                          </span> 
                                          <mat-icon class="" style="color:white; font-size: 24px; background-color:black; border-radius:50%;">
                                            arrow_forward
                                          </mat-icon>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                               <div class="col-12 mt-3 d-flex justify-content-center text-center" style="margin-left:0.2em;">
                                <div *ngIf="language == 'en' || language == 'es'" class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item container-video" style="padding-left: 1em;" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language == 'fr'" class="embed-responsive embed-responsive-16by9">
                                    <iframe style="padding-left: 1em;"class="embed-responsive-item container-video" src="https://www.youtube.com/embed/yeTPFqxAtSY" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language == 'it'" class="embed-responsive embed-responsive-16by9">
                                    <iframe style="padding-left: 1em;"class="embed-responsive-item container-video" src="https://www.youtube.com/embed/0z0SUEOepwY" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language !== 'it' && language !== 'fr' && language !== 'en' && language !== 'es'" class="embed-responsive embed-responsive-16by9">
                                  <iframe class="embed-responsive-item container-video" style="padding-left: 1em;" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                            </div> 
                            
                                <div class="col-12 cols ml-4 d-flex justify-content-center mt-3">
                                  <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                    <mat-icon color="white">close</mat-icon>
                                    {{ 'Cerrar' | translate }}
                                  </button>
                                </div>

                            </div>
                          </div>
                          </div>
                        </div>
          
                       
                      </div>
          
  
                      <!-- <a class="carousel-control-prev " href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a class="carousel-control-next " href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                      </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>




</div>
</body>
</html>








<!-- COOKIES -->
 <!-- <div class="modal-content2 centered-div d-flex justify-content-start" style="background-color: grey; padding: 10px;">
        <div class="row">
          <div class="col-12" style="padding: 5px;">
            <h4 class="texto-imge  text-white w-100 " style="padding: 10px; margin: 0;">{{'CONSENTIMIENTO PARA COOKIES Y DATOS' | translate }}</h4>
          </div>
        </div>
        </div>
        <div class="modal-content2 centered-div mt-5" style="background-color: grey; padding: 10px;">
          <div class="row">
            <div class="col-6">
              <p style="color: white; font-size: 12px; text-align: start;">{{'
                En este sitio web utilizamos cookies y funciones similares para procesar información de dispositivos finales y datos personales. El procesamiento se utiliza para fines como la integración de contenido, servicios externos y elementos de terceros, análisis/medición estadística, publicidad personalizada e integración de redes sociales. Dependiendo de la función, los datos Use transmiten a un máximo de 20 terceros y son procesados por ellos. Este consentimiento es voluntario, no requerido para el uso de nuestro sitio web y puede ser revocado en cualquier momento usando el icono en la parte inferior izquierda.' | translate }}
              </p>
              <div class="d-flex justify-content-start">
                <mat-slide-toggle style="margin: 10px;" class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <mat-icon>language</mat-icon>
                    <span style="margin-left: 5px;font-size: 10px;">Técnicamente Requerida</span>
                  </div>
                </mat-slide-toggle>
              <mat-slide-toggle style="margin:10px" class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                <mat-icon >widgets</mat-icon>
                <span style="margin-left: 5px;font-size: 10px;">Función</span>
              </div>
                </mat-slide-toggle>
              </div>
              <div class="d-flex justify-content-start MT-1">
                <mat-slide-toggle style="margin:10px" class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                  <mat-icon >campaign</mat-icon>
                  <span style="margin-left: 5px;font-size: 10px;">De Marketing y Publicidad</span>
                </div>
                  </mat-slide-toggle>
              </div>
            </div>
            <div class="col-5 justify-content-end mt-3" style="text-align: right; ">
              <div class="row">
                <div class="col-6">
                  <a [href]="url" target="_blank" type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white; white-space: normal; padding:0px;" data-dismiss="modal">
                      <mat-icon color="white">close</mat-icon>
                      {{'Rechazar todo' | translate }}
                  </a>
              </div>
                <div class="col-6">
                  <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center " style="font-size: 12px; background-color: black; color: white;padding:0px;" data-dismiss="modal">
                    <mat-icon color="white">check</mat-icon>
                    {{'Aceptar todo' | translate }}</button>
                </div>
              </div>
              <div class="row mt-3"> 
                <div class="col-12 text-center ">
                  <button type="button" class="btn bordes-btm d-flex align-items-center justify-content-center mx-auto mb-5" style="font-size: 12px; background-color: black; color: white; width: 50%; padding: 0;" data-dismiss="modal">
                      <mat-icon>check_circle_outline</mat-icon>
                      {{ 'Guardar + salir' | translate }}
                  </button>
                  <p class=" d-flex   text-center" style="color: white; font-size: 12px;">
                    <mat-icon class="">check_circle_outline</mat-icon>
                    Personaliza tu elección | Cookies | Más Información | T&C | Impresión
                  </p>
              </div>
              </div>

            </div>
          </div>
        </div>-->